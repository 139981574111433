const pages = {
    home: {
        name: 'product',
        path: '/sidebar/product'
    },
    status: {
        root: 'status',
        statusComingSoon: {
            name: 'coming-soon',
            path: '/status/coming-soon'
        },
        statusMaintenance: {
            name: 'maintenance',
            path: '/status/maintenance'
        },
        status404: {
            name: '404',
            path: '/status/404'
        },
        status500: {
            name: '500',
            path: '/status/500'
        },
        loading: {
            name: 'loading',
            path: '/status/loading'
        },
    },
}

export default pages;